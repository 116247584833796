

















import { Component, Vue, Prop } from 'vue-property-decorator'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'

@Component({
  components: {
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon
  }
})

export default class LoyaltyCheckbox extends Vue {
  @Prop({ default: 'label' }) private label!: string
  @Prop({ default: false }) private isChecked!: boolean
  @Prop({ default: false }) private disabled!: boolean

  private onClickCheckBox() {
    if (!this.disabled) {
      this.$emit('click')
    }
  }
}
