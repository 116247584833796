























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LoyaltyInput from '../../components/LoyaltyInput/index.vue'
import LoyaltyDropdown from '../../components/LoyaltyDropdown/index.vue'
import LoyaltyCheckbox from '../../components/LoyaltyCheckbox/index.vue'
import LoyaltyDatePicker from '../../components/LoyaltyDatePicker/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import dayjs from 'dayjs'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { Dropdown, EnumLoyaltiProgramChannel } from '@/app/infrastructures/misc/Constants/loyaltyProgram'
import {
  required,
  minValue,
  minLength,
  ValidationRule,
} from 'vuelidate/lib/validators'
import Modal from '@/app/ui/components/Modal/index.vue'
import ConfirmationIcon from '@/app/ui/assets/icon_confirmation.vue'
import { EventBusConstants } from '@/app/infrastructures/misc'
import controller, { MembershipFormulaPayload } from '@/app/ui/controllers/MembershipFormulaController'
import { MultiplierLevel } from '@/domain/entities/MembershipFormula'

interface IForm {
  channel: Dropdown
  nominal: number | string
  userType: Array<string>
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  multiplier: Array<string>
}

interface ValidationsInterface {
  form: {
    channel: {
      required: () => ValidationRule
    },
    nominal: {
      required: () => ValidationRule,
      minValue: ValidationRule;
    },
    userType: {
      required: () => ValidationRule,
      maxLength: ValidationRule,
    },
    startDate: {
      required: () => ValidationRule,
    },
    startTime: {
      required: () => ValidationRule,
    },
    endDate: {
      required: () => ValidationRule,
    },
    endTime: {
      required: () => ValidationRule,
    },
    multiplier:{
      required: () => ValidationRule,
      minLength: ValidationRule,
    }
  }
}

interface fieldMultiplierInterface {
  [key: string]: string
}

@Component({
  mixins: [validationMixin],
  components: {
    LoyaltyInput,
    LoyaltyDropdown,
    LoyaltyCheckbox,
    LoyaltyDatePicker,
    Button,
    LoadingOverlay,
    Modal,
    ConfirmationIcon,
  },
})
export default class CreateNewFormula extends Vue {
  multiplier: fieldMultiplierInterface = {}
  fieldMultiplier: fieldMultiplierInterface = {}
  controller = controller
  showModal = false
  showModalSuccess = false
  errorEndDate = false
  errorEndTime = false
  tomorrowDate = dayjs()
    .add(1, 'days')
    .format('YYYY-MM-DD')
  channel: Dropdown[] = [
    {
      label: EnumLoyaltiProgramChannel.CUSTOMER_APPS,
      value: EnumLoyaltiProgramChannel.CUSTOMER_APPS,
    },
    {
      label: EnumLoyaltiProgramChannel.MARKETPLACE,
      value: EnumLoyaltiProgramChannel.MARKETPLACE,
    },
    {
      label: EnumLoyaltiProgramChannel.RETAIL,
      value: EnumLoyaltiProgramChannel.RETAIL,
    },
    {
      label: EnumLoyaltiProgramChannel.NEW_COD,
      value: EnumLoyaltiProgramChannel.NEW_COD
    }
  ]
  userType: Record<string, boolean> = {
    sender: false,
    receiver: false,
  }
  form: IForm = {
    channel: this.channel[-1],
    nominal: '',
    userType: [],
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    multiplier: [],
  }

  created(): void {
    this.controller.getMultiplierLevel()
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        channel: { required },
        nominal: {
          required,
          minValue: minValue(1),
        },
        userType: {
          required,
          maxLength: minLength(1),
        },
        startDate: { required },
        startTime: { required },
        endDate: { required },
        endTime: { required },
        multiplier: {
          required,
          minLength: minLength(5),
        },
      },
    }
  }

  get minimumEndDate(): string {
    const startDate: string = <string>this.form.startDate
    if (!startDate) {
      return this.tomorrowDate
    } else {
      return dayjs(startDate)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    }
  }

  private goToBack(): void {
    this.$router.push('/loyalty-program/membership-poin-formula')
  }

  private onClickCheckbox(name: string): void {
    if ((<string[]>this.form.userType).includes(name)) {
      this.form.userType = (<string[]>this.form.userType).filter(
        item => item !== name
      )
      this.userType[name] = false
    } else {
      (<string[]>this.form.userType).push(name)
      this.userType[name] = true
    }
  }

  private createMembershipFormula(): void {
    const payload: Omit<MembershipFormulaPayload, 'id'> = {
      channel: this.form.channel?.value,
      nominal: this.form.nominal,
      isSender: this.userType.sender,
      isReceiver: this.userType.receiver,
      levelInfo: (<MultiplierLevel[]>this.controller.listMultiplierLevel.data).map((item, index) => {
        return {
          id: item.levelId,
          multiplier: Number(this.fieldMultiplier[this.form.multiplier[index]]),
        }
      }),
      startDate: dayjs(
        `${this.form.startDate} ${this.form.startTime}`
      ).toISOString(),
      endDate: dayjs(`${this.form.endDate} ${this.form.endTime}`).toISOString(),
    }
    this.controller.create(payload)
  }

  private onSubmit(): void {
    this.showModal = true
  }

  private onModalAction(): void {
    this.showModal = false
    this.createMembershipFormula()
  }

  private goToMembershipFormula(): void {
    this.$router.push('/loyalty-program/membership-poin-formula')
  }

  @Watch('form.startDate')
  @Watch('form.startTime')
  onStartDateChanged(): void {
    if(this.form.endDate) {
      this.errorEndDate = true
      this.errorEndTime = true
      this.form.endDate = ''
      this.form.endTime = ''
    }
  }

  @Watch('form.endDate')
  onChangeEndDate():void {
    if(this.form.endDate) this.errorEndDate = false
  }

  @Watch('form.endTime')
  onChangeEndTime(): void {
    if(this.form.endTime) this.errorEndTime = false
  }

  @Watch('fieldMultiplier', { immediate: true, deep: true })
  onChangedMultiplier(value: fieldMultiplierInterface): void {
    Object.keys(value).forEach(item => {
      this.multiplier[item] = value[item]
      if (typeof value[item] === 'number') {
        if ((<string[]>this.form.multiplier).indexOf(item) < 0) {
          (<string[]>this.form.multiplier).push(item)
        }
      } else {
        this.form.multiplier = (<string[]>this.form.multiplier).filter(
          val => val !== item
        )
      }
    })
  }

  @Watch('controller.listMultiplierLevel.data')
  onGetMultiplier(value: MultiplierLevel[]): void {
    value.forEach((item: MultiplierLevel, index: number) => {
      this.multiplier[item.levelName ?? `lavel-${index}`] = ''
    })
  }

  @Watch('controller.errCreate')
  onErrorCreate(value: string): void {
    if(
      value !== '' &&
      value === EventBusConstants.CREATE_LOYALTY_PROGRAM_MEMBERSHIP_FORMULA) {
        this.showModalSuccess = true
        this.controller.setErrCreate('')
      }
  }
}
