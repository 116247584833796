







































import { Component, Vue, Prop } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import InputMultiTags from '@/app/ui/components/InputMultiTags/index.vue'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  components: {
    TextInput,
    InputMultiTags,
  },
})
export default class BaseInput extends Vue {
  @Prop({ default: false }) private required!: boolean
  @Prop({ default: 'label' }) private label!: string
  @Prop({ default: false }) private isMultiTag!: false
  @Prop({ default: 'input-tags' }) private id!: string
  @Prop({ default: 'text' }) private type!: string
  @Prop({ default: '' }) private errorMessage!: string
  @Prop({ default: '' }) private fieldDescription!: string

  get displayValue(): string {
    if (this.type === 'currency') {
      return Utils.currencyDigit(Number(this.$attrs.value)).toString()
    }
    return this.$attrs.value
  }

  set displayValue(modifiedValue: string) {
    if (isNaN(Number(modifiedValue)) && this.type === 'textNumber') {
      return
    }
    if (modifiedValue === '') {
      this.$emit('input', '')
      return
    }

    if (this.type === 'currency' || this.type === 'number') {
      let newNumber = Number(modifiedValue.replace(/[^0-9]+/g, ''))
      if (isNaN(newNumber)) {
        newNumber = 0
      }

      this.$emit('input', newNumber)

      return
    }

    if (this.type === 'textNumber') {
      let newValue = Number(modifiedValue.replace(/[^0-9.]+/g, ''))
      if (!new RegExp(/^\d*(\.\d{1,3})?$/, 'g').test(String(newValue))) {
        newValue = 0
      }

      // $emit the event so that parent component gets it
      this.$emit('input', newValue)

      return
    }

    this.$emit('input', modifiedValue)
  }

  private onKeypress($event: {
    keyCode: number
    which: number
    preventDefault: () => void
    target: HTMLInputElement
  }): void {
    if (
      this.type === 'currency' ||
      this.type === 'number' ||
      this.type === 'phone' ||
      this.type === 'textNumber'
    ) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (this.type === 'currency' || this.type === 'textNumber') {
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          $event.preventDefault()
        }
      }
    }
  }
}
