




















import { Component, Vue, Prop } from 'vue-property-decorator';
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'

@Component({
  components: {
    DateTimePicker
  }
})

export default class LoyaltyDatePicker extends Vue {
  @Prop({ default: 'label' }) private fieldLabel!: string
  @Prop({ default: false }) private required!: boolean
}
